<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="showDeleteButton"
		tooltipMsg="manualofdetailc"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openModal()"
	>
		<template #header>
			{{ $t('detailofcountry') }}
			<b-link target="_blank" :href="siteUrl">
				<!-- {{ model.CountryId === 1 ? model.CountryNameSk : model.CountryNameCz }} -->
				{{ model.Name }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.Id }}</label>
		</template>

		<template #beforeFormActions>
			<google-map
				:latitude="+model.CountryGoogleMapsGpsLat"
				:longitude="+model.CountryGoogleMapsGpsLng"
				:boundaries="model.CountryBoundaries"
				:zoom="+model.CountryGoogleMapsZoom"
				:onChanged="coordinatesChanged"
				height="700"
				:boundariesEnabled="false"
			>
			</google-map>
		</template>
	</detail-page>
</template>

<script>
import vue from 'vue';
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import { model, fields } from './country-form.data';

import apiCommon from '../../services/api/common.api';
import serviceCommon from '../../services/service/common.service';
import serviceCommonApi from '@/services/api/common.api';
import serviceEnums from '../../services/service/enums.service';
import * as links from '../.././router/links';
import loading from '../../mixins/loading.mixin';
import enums from '@/services/helpers/enums';
import { mapFormSchemaFields } from '@/components/general/detail-page/service';
import siteUrlBuilder from '@/services/helpers/site-url-builder';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'Country',
			routeParam: 'CountryId',
			model,
			schema: {
				groups: fields,
			},
			baseInfoMessageFieldName: 'CountryInfoMessage',
		};
	},

	computed: {
		showDeleteButton() {
			return !!this.model.CountryId;
		},

		siteUrl() {
			if (this.model.CountryDomainName) {
				console.debug('siteUrlBuilder: ', siteUrlBuilder.buildUrl(this.model.CountryDomainName));
				return siteUrlBuilder.buildUrl(this.model.CountryDomainName);
			}
		},

		countryInfoMessageFieldsModel() {
			const countryInfoMessageFieldsName = [];

			mapFormSchemaFields(this.schema, (field) => {
				if (field.model.startsWith(this.baseInfoMessageFieldName)) {
					countryInfoMessageFieldsName.push(field.model);
				}
			});

			return countryInfoMessageFieldsName;
		},
	},

	created() {
		/* TODO overit ci to netreba presunut do globalneho nastavenia
			var res = vueFormGenerator.validators.resources;
			res.fieldIsRequired = this.$i18n.t('amistake!');
			*/
	},

	methods: {
		async loadResources() {
			const currencies = await this.getCurrencyEnum();
			const statuses = this.getCountryStatusEnum();
			const countries = this.getCountryEnum();
			const countryLanguages = this.getCountryLanguagesEnum();

			this.updateSchemaFields({
				CurrencyId: { values: currencies },
				CountryStatus: { values: statuses },
				InfoMessageSelector: {
					values: countries,
					onChanged: this.handleInfoMessageVisibility,
				},
				LanguageCode: {
					values: countryLanguages,
				},
				CountryLanguages: {
					values: countryLanguages,
				},
			});
		},

		transformDtoToModel(dtoModel) {
			dtoModel.CountryGoogleMapsGpsLat = +dtoModel.CountryGoogleMapsGpsLat;
			dtoModel.CountryGoogleMapsGpsLng = +dtoModel.CountryGoogleMapsGpsLng;
			dtoModel.InfoMessageSelector = dtoModel.Id;
			this.handleInfoMessageVisibility(null, dtoModel.Id);
			return dtoModel;
		},

		async coordinatesChanged(lat, lng, zoom, polygonPaths) {
			const toModelUpdate = {};
			if (lat) {
				toModelUpdate.CountryGoogleMapsGpsLat = lat;
			}
			if (lng) {
				toModelUpdate.CountryGoogleMapsGpsLng = lng;
			}
			if (zoom) {
				toModelUpdate.CountryGoogleMapsZoom = zoom;
			}
			if (polygonPaths) {
				toModelUpdate.CountryyBoundaries = JSON.stringify(polygonPaths);
			}
			this.updateFormModel(toModelUpdate);
			await this.$refs[this.detailPageRefName].validate();
		},

		async getCurrencyEnum() {
			const resp = await serviceCommonApi.getStandardList({}, 'Currency');
			return resp.data.map((cur) => ({
				id: cur.CurrencyId,
				name: cur.CurrencyCode,
			}));
		},

		getCountryStatusEnum() {
			return serviceEnums.getEnumForVFG('countryStatus');
		},

		getCountryLanguagesEnum() {
			return serviceEnums.getEnumForVFG('countryLanguageCodes');
		},

		getCountryEnum() {
			return this.$store.state.country.data;
		},

		handleInfoMessageVisibility(model, countryId) {
			const countryCode = getCountryCodeById(countryId);

			const toUpdate = this.countryInfoMessageFieldsModel.reduce((res, fieldModel) => {
				res[fieldModel] = { visible: fieldModel === `${this.baseInfoMessageFieldName}${countryCode}` };
				return res;
			}, {});

			this.updateSchemaFields(toUpdate);

			function getCountryCodeById(countryId) {
				const [code, id] = Object.entries(enums.country).find(([code, id]) => id === countryId) || [
					countryId.toString(),
					countryId,
				];
				return upperCaseOnlyFirst(code);

				function upperCaseOnlyFirst(str) {
					return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
				}
			}
		},

		// openModal() {
		// 	serviceCommon.openDeleteModal(this.model.TerritoryId, this.$modal, this.deleteModel);
		// },

		// getCountryEnum() {
		// 	this.updateSchemaFields({ CountryId: { values: this.$store.state.country.data } });
		// },
	},
};
</script>

<style lang="scss">
.vue-form-generator .inline-input-group {
	.form-group.error {
		position: relative;

		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}

	@include ig-first-item(23%, 79%, 22%);
	@include ig-second-item(10%, 10px);
}
</style>