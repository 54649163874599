import vueFormGenerator from 'vue-form-generator';
import enums from '@/services/helpers/enums';

export const model = {
	Id: 0,
	Name: null,
	CountryStatus: null,
	LanguageCode: null,
	CountryLanguages: null,
	InfoMessageSelector: enums.country.SK,
	CountryInfoMessageSk: null,
	CountryInfoMessageCz: null,
	CountryGoogleMapsGpsLat: null,
	CountryGoogleMapsGpsLng: null,
	CountryGoogleMapsZoom: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'CountryStatus',
				required: true,
				//validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'quarter-width',
				i18n: {
					label: 'availabilitysta',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'Code',
				required: true,
				i18n: {
					label: 'countrycode',
				},
				styleClasses: 'quarter-width',
			},
			{
				type: 'select',
				model: 'CurrencyId',
				required: true,
				//validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'quarter-width',
				i18n: {
					label: 'currency3387',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryDomainName',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'domain3388',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'Name',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localsk2557',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localcz2559',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalPl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localcz2560',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localde2563',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CountryNameLokalHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'localhu2567',
				},
			},
			{
				type: 'radios',
				model: 'LanguageCode',
				values: [],
				required: true,
				validator: vueFormGenerator.validators.required,
				radiosOptions: {
					value: 'id',
					name: 'name',
				},
				i18n: {
					label: 'defaultlanguage',
				},
				styleClasses: 'inline-radio-list',
			},
			{
				type: 'checkboxes-group',
				model: 'CountryLanguages',
				values: [],
				checklistOptions: {
					value: 'id',
					name: 'name',
				},
				i18n: {
					label: 'languageofthela',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'PriceMax',
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'maximumsearchpr',
				},
				styleClasses: 'quarter-width',
			},
		],
	},

	{
		styleClasses: 'inline-input-group',
		fields: [
			{
				type: 'input',
				inputType: 'number',
				model: 'CheapestPriceMin',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'cheapestaccommo',
				},
				styleClasses: 'first-input',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CheapestPriceMax',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'to3392',
				},
				styleClasses: 'second-input',
			},
		],
	},
	{
		styleClasses: 'inline-input-group',
		fields: [
			{
				type: 'input',
				inputType: 'number',
				model: 'CheapPriceMin',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'cheapaccommodat',
				},
				styleClasses: 'first-input',
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CheapPriceMax',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'to3392',
				},
				styleClasses: 'second-input',
			},

			{
				type: 'radios',
				model: 'InfoMessageSelector',
				values: [],
				radiosOptions: {
					value: 'Id',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languagetransla',
				},
				styleClasses: 'inline-radio-list',
			},
			{
				type: 'html-text-editor',
				model: 'CountryInfoMessageSk',
				imageUpload: true,
				i18n: {
					label: 'messageforthela',
				},
			},
			{
				type: 'html-text-editor',
				model: 'CountryInfoMessageCz',
				imageUpload: true,
				i18n: {
					label: 'messageforthela',
				},
				visible: false,
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CountryGoogleMapsGpsLat',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CountryGoogleMapsGpsLng',
				required: true,
				validator: vueFormGenerator.validators.double,
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CountryGoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
