var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: _vm.showDeleteButton,
          tooltipMsg: "manualofdetailc",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("detailofcountry")) + "\n\t\t"
                  ),
                  _c(
                    "b-link",
                    { attrs: { target: "_blank", href: _vm.siteUrl } },
                    [_vm._v("\n\t\t\t" + _vm._s(_vm.model.Name) + "\n\t\t")]
                  ),
                  _c("label", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("id")) + " " + _vm._s(_vm.model.Id)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "beforeFormActions",
              fn: function () {
                return [
                  _c("google-map", {
                    attrs: {
                      latitude: +_vm.model.CountryGoogleMapsGpsLat,
                      longitude: +_vm.model.CountryGoogleMapsGpsLng,
                      boundaries: _vm.model.CountryBoundaries,
                      zoom: +_vm.model.CountryGoogleMapsZoom,
                      onChanged: _vm.coordinatesChanged,
                      height: "700",
                      boundariesEnabled: false,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1457560147
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }